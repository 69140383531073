<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="py-2 mb-10">
      <v-card-title>
        การเงิน > <a href="#" class="mx-2" @click="back_allinvoice()">ใบแจ้งหนี้ทั้งหมด</a> > {{listOrderInvoice.invoiceNo}}
        <v-spacer/>
        <v-btn icon @click="dialog_print_open()"><v-icon color="success">mdi-printer</v-icon></v-btn>
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="mb-10">
      <v-card-text>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b>เลขที่ invoice : </b></v-col>
          <v-col cols="12" md="6" xl="9">{{listOrderInvoice.invoiceNo}}</v-col>
        </v-row>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b> วันที่ออก : </b></v-col>
          <v-col cols="12" md="6" xl="9">{{ set_format_date_time(listOrderInvoice.invoiceDate).substr(0, 10) }}</v-col>
        </v-row>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b> เวลาที่สร้าง : </b></v-col>
          <v-col cols="12" md="6" xl="9">
            {{ set_format_date_time(detail_created) }}
          </v-col>
        </v-row>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b>สร้างโดย : </b></v-col>
          <v-col cols="12" md="6" xl="9">{{detail_createdByName}}</v-col>
        </v-row>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b>ร้านค้า : </b></v-col>
          <v-col cols="12" md="6" xl="9">{{detail_shopName}}</v-col>
        </v-row>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b>เลขที่อ้างอิง : </b></v-col>
          <v-col cols="12" md="6" xl="9">{{detail_groupName}}</v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ที่อยู่ Branch -->
    <v-card elevation="0" class="my-0 mb-10">
      <v-card-title >
        <span v-if="branchDisibled == true">ที่อยู่ใบกำกับภาษีสาขา</span>
        <span v-else>แก้ไขที่อยู่ใบกำกับภาษีสาขา</span>
        <v-spacer></v-spacer>
        <v-btn icon v-if="branchDisibled == true" @click="branchDisibled = !branchDisibled, rd_branch_action='1'"><v-icon size="18" >fa-edit</v-icon></v-btn>
        <v-btn icon v-else @click="branchDisibled = !branchDisibled , rd_branch_action='1'"><v-icon size="18" >fa-edit</v-icon></v-btn>
      </v-card-title>

      <!-- แสดงที่อยู่ branch -->
      <v-card-text v-if="branchDisibled == true">
        <v-row row >
          <v-col class="pb-0" cols="12">
            {{branch_FullAddress_text}}
          </v-col>
        </v-row>
      </v-card-text>

      <!-- แก้ไขที่อยู่ branch -->
      <v-card-text v-else>
        <v-row>
          <v-col cols="12">
            <v-radio-group
              v-model="rd_branch_action"
              row
            >
              <v-radio
                label="เลือกจากที่มีอยู่"
                value="1"
              ></v-radio>
              <v-radio
                label="เพิ่มที่อยู่ใหม่"
                value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <div v-if="rd_branch_action == '2'">
          <br>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                <h4>  ชื่อบุคคล/นิติบุคคล :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="BranchAddressInvoice.Name"
                    @change="value => BranchAddressInvoice.Name = value"
                    autocomplete="off"
                    ref="refBranchAddressInvoiceName"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>เลขผู้เสียภาษี :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="BranchAddressInvoice.IDCardNumber"
                    @change="value => BranchAddressInvoice.IDCardNumber = value"
                    autocomplete="off"
                    ref="refBranchAddressInvoiceIDCardNumber"
                    maxlength="20"
                    outlined
                    hide-details
                    @keypress="isNumberWNoDot($event)"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>เบอร์โทร :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="BranchAddressInvoice.Phone"
                    @change="value => BranchAddressInvoice.Phone = value"
                    autocomplete="off"
                    ref="refBranchAddressInvoicePhone"
                    maxlength="10"
                    outlined
                    hide-details
                    @keypress="isNumberWNoDot($event)"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                <h4>  ที่อยู่ :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="BranchAddressInvoice.TextAddress"
                    @change="value => BranchAddressInvoice.TextAddress = value"
                    autocomplete="off"
                    ref="refBranchAddressInvoiceTextAddress"
                    outlined
                    hide-details
                    dense

                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>รหัสไปรษณีย์ :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-autocomplete
                    :value="BranchAddressInvoice.Address"
                    @change="value => BranchAddressInvoice.Address = value"
                    autocomplete="off"
                    ref="refBranchAddressInvoiceAddress"
                    placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                    :items="postcode_mapping"
                    :item-text="item => item.postcode + ' ' + item.province + ' ' + item.district + ' ' + item.subDistrict"
                    outlined
                    required
                    return-object
                    hide-details
                    dense

                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <v-autocomplete
            v-model="select_branch_ad_iv_id"
            style="max-width: 1000px"
            :items="branch_ad_iv_list"
            :item-text="item => item.fullAddress"
            :item-value="item => item.id"
            dense
            filled
            outlined

          ></v-autocomplete>
        </div>


        <v-row>
          <v-col class="pb-2" cols="12" md="12">
            <v-row row >
              <v-col class="pb-2" cols="12" md="12" align="left">
                <v-btn v-if="rd_branch_action == '1'" dark color="primary" @click="AddandEditBranchAddressInvoice('update')" class="mr-1"><v-icon left >mdi-content-save</v-icon> บันทึก</v-btn>
                <v-btn v-else dark color="success" @click="AddandEditBranchAddressInvoice('add')" class="mr-1"><v-icon left >mdi-content-save</v-icon> เพิ่มและบันทึก</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ที่อยู่ร้านค้า -->
    <v-card elevation="0" class="my-0 mb-10">
      <v-card-title >
        <span v-if="shopDisibled == true">ที่อยู่ใบกำกับภาษีร้านค้า</span>
        <span v-else>แก้ไขที่อยู่ใบกำกับภาษีร้านค้า</span>
        <v-spacer></v-spacer>
        <v-btn icon v-if="shopDisibled == true" @click="shopDisibled = !shopDisibled , rd_shop_action='1'"><v-icon size="18" >fa-edit</v-icon></v-btn>
        <v-btn icon v-else @click="shopDisibled = !shopDisibled, rd_shop_action='1' "><v-icon size="18" >fa-edit</v-icon></v-btn>
      </v-card-title>

      <!-- แสดงที่อยู่ shop -->
      <v-card-text v-if="shopDisibled == true">
        <v-row row >
          <v-col class="pb-0" cols="12">
            {{Shop_FullAddress_text}}
          </v-col>
        </v-row>
      </v-card-text>

      <!-- แก้ไขที่อยู่ shop -->
      <v-card-text v-else>
        <v-row>
          <v-col cols="12">
            <v-radio-group
              v-model="rd_shop_action"
              row
            >
              <v-radio
                label="เลือกจากที่มีอยู่"
                value="1"
              ></v-radio>
              <v-radio
                label="เพิ่มที่อยู่ใหม่"
                value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <div v-if="rd_shop_action == '2'">
          <br>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                <h4>  ชื่อบุคคล/นิติบุคคล :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="ShopAddressInvoice.Name"
                    @change="value => ShopAddressInvoice.Name = value"
                    autocomplete="off"
                    ref="refShopAddressInvoiceName"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>เลขผู้เสียภาษี :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="ShopAddressInvoice.IDCardNumber"
                    @change="value => ShopAddressInvoice.IDCardNumber = value"
                    autocomplete="off"
                    ref="refShopAddressInvoiceIDCardNumber"
                    maxlength="20"
                    outlined
                    hide-details
                    @keypress="isNumberWNoDot($event)"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>เบอร์โทร :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="ShopAddressInvoice.Phone"
                    @change="value => ShopAddressInvoice.Phone = value"
                    autocomplete="off"
                    ref="refShopAddressInvoicePhone"
                    maxlength="10"
                    outlined
                    hide-details
                    @keypress="isNumberWNoDot($event)"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                <h4>  ที่อยู่ :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="ShopAddressInvoice.TextAddress"
                    @change="value => ShopAddressInvoice.TextAddress = value"
                    autocomplete="off"
                    ref="refShopAddressInvoiceTextAddress"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>รหัสไปรษณีย์ :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-autocomplete
                    v-model="ShopAddressInvoice.Address"
                    ref="refShopAddressInvoiceAddress"
                    placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                    :items="postcode_mapping"
                    :item-text="item => item.postcode + ' ' + item.province + ' ' + item.district + ' ' + item.subDistrict"
                    outlined
                    required
                    return-object
                    hide-details
                    dense

                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-autocomplete
            v-model="select_shop_ad_iv_id"
            style="max-width: 1000px"
            :items="shop_ad_iv_list"
            :item-text="item => item.fullAddress"
            :item-value="item => item.id"
            dense
            filled
            outlined
          ></v-autocomplete>
        </div>

        <v-row>
          <v-col class="pb-2" cols="12" md="12">
            <v-row row >
              <v-col class="pb-2" cols="12" md="12" align="left">
                <v-btn v-if="rd_shop_action == '1'" dark color="primary" @click="AddandEditShopAddressInvoice('update')" class="mr-1"><v-icon left >mdi-content-save</v-icon> บันทึก</v-btn>
                <v-btn v-else dark color="success" @click="AddandEditShopAddressInvoice('add')" class="mr-1"><v-icon left >mdi-content-save</v-icon> เพิ่มและบันทึก</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ข้อมูล invoice items -->
    <v-card elevation="0" class="my-0 mb-10">
      <v-card-title >
        <span v-if="invoiceItemDisibled == true">รายละเอียด</span>
        <span v-else>แก้ไขรายละเอียด</span>
        <v-spacer></v-spacer>
        <v-btn icon v-if="invoiceItemDisibled == true" @click="invoiceItemDisibled = !invoiceItemDisibled, calculateVAT()"><v-icon size="18" >fa-edit</v-icon></v-btn>
        <v-btn icon v-else @click="invoiceItemDisibled = !invoiceItemDisibled, calculateVAT()"><v-icon size="18" >fa-edit</v-icon></v-btn>
      </v-card-title>
      <!-- <v-divider/> -->
      <v-card-text v-if="invoiceItemDisibled == false">
        <v-row class="my-3">
          <v-col cols="12">
            <v-radio-group
              @change="calculateVAT()"
              v-model="radio_vat_type_select"
              row
            >
              <v-radio
                label="ไม่มีภาษี (No VAT)"
                value="1"
              ></v-radio>
              <v-radio
                label="รวมภาษี (VAT Included)"
                value="2"
              ></v-radio>
              <v-radio
                label="ไม่รวมภาษี (VAT Excluded)"
                value="3"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="elevation-0 packhai-border-table packhai-table"
              :headers="[
                { text: '', value: 'delete' ,align: 'center', sortable: false, width: '10px'},
                { text: 'ลำดับ', value: 'num' ,align: 'center', sortable: false, width: '10px' },
                { text: 'รายละเอียด', value: 'description' ,align: 'left', sortable: false, width: '300px' },
                { text: 'จำนวน', value: 'quantity' ,align: 'right', sortable: false, width: '100px' },
                { text: 'ราคา', value: 'unitPrice' ,align: 'right', sortable: false, width: '100px' },
                { text: 'ราคารวม', value: 'total_price' ,align: 'right', sortable: false, width: '100px' }
              ]"
              :items="bill_for_created_list"
              hide-default-footer
              :items-per-page="10000"
              :mobile-breakpoint="0"
            >
            <template v-slot:item.delete="{ item }">
              <v-btn v-if="bill_for_created_list.length > 1" icon color="danger" @click="delete_item(item)"><v-icon>fa-trash-alt</v-icon></v-btn>
              <v-btn v-else icon ><v-icon>fa-trash-alt</v-icon></v-btn>
            </template>
            <template v-slot:item.num="{ item }">
              {{ bill_for_created_list.indexOf(item) + 1 }}
            </template>

            <template v-slot:item.description="{ item }">
              <v-text-field
                ref="descriptionInput"
                :value="item.description"
                @change="value => item.description = value"
                autocomplete="off"
                placeholder="รายละเอียด"
                required
                outlined
                hide-details
                @blur="descriptionInput(item)"
                ></v-text-field>
            </template>
            <template v-slot:item.quantity="{ item }">
              <!-- {{ formatMoney(item.quantity) }} -->
                <v-text-field
                  ref="quantityInput"
                  :value="item.quantity"
                  @change="value => item.quantity = value"
                  autocomplete="off"
                  placeholder="จำนวน"
                  required
                  outlined
                  type="number"
                  hide-details
                  @blur="quantityInput(item)"
                ></v-text-field>
              </template>
            <template v-slot:item.unitPrice="{ item }">
                <!-- {{ formatMoney(item.unitPrice) }} -->
                <v-text-field
                  ref="priceInput"
                  :value="item.unitPrice"
                  @change="value => item.unitPrice = value"
                  autocomplete="off"
                  placeholder="ราคา"
                  type="number"
                  required
                  outlined
                  hide-details
                  @blur="priceInput(item)"
                ></v-text-field>
            </template>

            <template v-slot:item.total_price="{ item }">
                {{ formatMoney(item.total_price) }}
            </template>

            </v-data-table>
          </v-col>
          <v-col cols="12" class="my-2"> <v-btn color="info" outlined @click="add_data_bill()"> <v-icon left> fa-plus </v-icon> เพิ่มแถว </v-btn> </v-col>
        </v-row>
        <v-row justify="end">
            <v-col lg="5">
                <v-divider></v-divider>
                <v-row class="py-3">
                    <v-col>
                        ยอดรวม
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ formatMoney(this.sum_price.toFixed(2)) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="py-3">
                    <v-col>
                      ยอดที่คำนวนภาษี
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ formatMoney(this.sum_price_vat.toFixed(2)) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                  <v-row class="py-3">
                      <v-col>
                      ภาษีมูลค่าเพิ่ม
                      <span v-if="radio_vat_type_select == '1'">(No VAT)</span>
                      <span v-if="radio_vat_type_select == '2'">(VAT Included)</span>
                      <span v-if="radio_vat_type_select == '3'">(VAT Excluded)</span>
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ formatMoney(this.sum_vat.toFixed(2)) }}</span>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
                    <v-row class="py-3">
                        <v-col>
                        ยอดรวมทั้งหมด
                        </v-col>
                        <v-col cols="4" lg="3" align="right">
                            <span>{{ formatMoney(this.sum_all.toFixed(2)) }}</span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn dark color="primary" @click="update_iv_item()" class="mr-1"><v-icon left >mdi-content-save</v-icon> บันทึก</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="elevation-0 packhai-border-table packhai-table"
              :headers="[
                { text: 'ลำดับ', value: 'num' ,align: 'center', sortable: false, width: '10px' },
                { text: 'รายละเอียด', value: 'description' ,align: 'left', sortable: false, width: '300px' },
                { text: 'จำนวน', value: 'quantity' ,align: 'right', sortable: false, width: '100px' },
                { text: 'ราคา', value: 'unitPrice' ,align: 'right', sortable: false, width: '100px' },
                { text: 'ราคารวม', value: 'total_price' ,align: 'right', sortable: false, width: '100px' }
              ]"
              :items="bill_for_created_list"
              hide-default-footer
              :items-per-page="10000"
              :mobile-breakpoint="0"
            >
            <template v-slot:item.num="{ item }">
              {{ bill_for_created_list.indexOf(item) + 1 }}
            </template>

            <template v-slot:item.quantity="{ item }">
              {{ formatMoney(item.quantity) }}
            </template>
            <template v-slot:item.unitPrice="{ item }">
                {{ formatMoney(item.unitPrice) }}
            </template>

            <template v-slot:item.total_price="{ item }">
                {{ formatMoney(item.total_price) }}
            </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row justify="end">
            <v-col lg="5">

                <v-divider></v-divider>
                <v-row class="py-3">
                    <v-col>
                        ยอดรวม
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ formatMoney(this.sum_price.toFixed(2)) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="py-3">
                    <v-col>
                      ยอดที่คำนวนภาษี
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ formatMoney(this.sum_price_vat.toFixed(2)) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                  <v-row class="py-3">
                      <v-col>
                      ภาษีมูลค่าเพิ่ม
                      <span v-if="radio_vat_type_select == '1'">(No VAT)</span>
                      <span v-if="radio_vat_type_select == '2'">(VAT Included)</span>
                      <span v-if="radio_vat_type_select == '3'">(VAT Excluded)</span>
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ formatMoney(this.sum_vat.toFixed(2)) }}</span>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
                    <v-row class="py-3">
                        <v-col>
                        ยอดรวมทั้งหมด
                        </v-col>
                        <v-col cols="4" lg="3" align="right">
                            <span>{{ formatMoney(this.sum_all.toFixed(2)) }}</span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
            </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ข้อมูล order PC -->
    <v-card elevation="0" class="my-0" v-if="ch_InvoiceGroupID != null">
      <v-card-title >
        <span class="my-2">ข้อมูลรายการ</span>
        <v-btn icon >
          <vue-excel-xlsx
              :data="data_group_detailt_for_excel"
              :columns="excel_detail_invoice_list_header"
              :file-name="'รายงานข้อมูลใบแจ้งหนี้'"
              :sheet-name="'รายงานข้อมูลใบแจ้งหนี้'"
              >
              <v-icon color="success">mdi-file-excel</v-icon>
          </vue-excel-xlsx>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="overflow-y-auto">
          <v-data-table
            :headers="listOrderInvoice.headers"
            :key="listOrderInvoice.key_table"
            :items="listOrderInvoice.table"
            :footer-props="shop.footer_props"
            item-key="orderID"
            class="elevation-0 packhai-border-table"
            :mobile-breakpoint="0"
            >
            <template v-slot:item.num="{ item }">
              <font v-if="item['orderID'] != 'รวมยอด'">{{ listOrderInvoice.table.indexOf(item) +1 }}</font>
            </template>
            <template v-slot:item.orderID="{ item }">
              <a href="#" v-if="item['orderID'] != 'รวมยอด'" @click="detail_order(item)">{{ item['orderID'] }}</a>
              <span v-else>{{ item['orderID'] }}</span>
            </template>
            <template v-slot:item.expressCompanyLogo="{ item }">
              <v-row class="pa-0 ma-0" align="center">
                <v-col class="pa-0 ma-0"  align="center">
                  <v-img :src="item.expressCompanyLogo == null ? '' : item.expressCompanyLogo" width="50" height="auto" ></v-img>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </div>

        <v-row justify="end" v-if="data_group_detail != null">
            <v-col lg="5">
                <v-row class="py-3 px-4">
                    <v-col>
                        ค่าขนส่ง
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ data_group_detail.deliveryPrice }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="py-3 px-4">
                    <v-col>
                        ค่าบริการ COD
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ data_group_detail.codCharged }}</span>
                    </v-col>
                </v-row>
                <!-- <v-divider></v-divider> -->
                <v-row class="py-3 px-4">
                    <v-col>
                        ภาษีมูลค่าเพิ่ม
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ data_group_detail.codVatAmount }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="py-3 px-4">
                    <v-col>
                        ค่าบริการอื่นๆ
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ data_group_detail.servicePrice }}</span>
                    </v-col>
                </v-row>
                <!-- <v-divider></v-divider> -->
                <v-row class="py-3 px-4">
                    <v-col>
                        ภาษีมูลค่าเพิ่ม
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ data_group_detail.serviceVatAmount }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-row class="py-3 px-4 font-weight-bold">
                    <v-col>
                        รวมยอดทั้งหมด
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ data_group_detail.totalPrice }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog_select_print" width="700">
      <v-card elevation="1">
        <v-card-title class="pr-1">
          ปริ้น
          <v-spacer/>
          <v-btn icon @click="dialog_select_print=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pl-7 pb-0">
          <div class="py-3">

            <v-radio-group v-model="select_print_type" row>
            <v-radio v-for="i in select_print_type_List" color="info" v-bind:key="i['value']" :label="i['label']" :value="i['value']"></v-radio>
            </v-radio-group>

            <!-- <v-radio-group
              v-model="select_print_type"
              row
            >
              <v-radio
                label="ใบแจ้งหนี้"
                :value="1"
              ></v-radio>
              <v-radio
                label="ใบเสร็จรับเงิน / ใบกำกับภาษี"
                :value="2"
              ></v-radio>
            </v-radio-group> -->
          </div>
          <div>
            <v-menu
              ref="menuSelectInvoiceDate"
              v-model="menuSelectInvoiceDate"
              :close-on-content-click="false"
              :return-value.sync="selectInvoiceDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  :value="selectInvoiceDate"
                  @change="value => selectInvoiceDate = value"
                  autocomplete="off"
                  label="วันที่ออกใบแจ้งหนี้"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  hide-details
                  style="max-width: 300px"

                ></v-text-field>
              </template>
              <v-date-picker v-model="selectInvoiceDate" no-title scrollable :allowed-dates="allowedDates">
              </v-date-picker>
            </v-menu>
          </div>
          <div class="pt-3 pb-5">
            <v-btn outlined color="info" @click="PrintPreview()">
              ตกลง
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { reportService_dotnet, shopService_dotnet, branchService_dotnet, generalService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
  import { FormatInvoice, FormatOrder, formatMoney, isNumberWNoDot, set_format_date_time, format_price, format_vat_type,getPostcodeMapping, get_premission_manage, permission_alert } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,

      navigete: [],
      detail_shopName : null,
      detail_createdByName : null,
      detail_created : null,
      detail_groupName : null,
      shop: {
        'footer_props': {
           'items-per-page-options': [50],
           'items-per-page-text': null,
           'disable-items-per-page': true
        },
        'list': [],
        'shop_select': '',
        'selected_to_make_invoice': [],
        'key_table': 0
      },

      listOrderInvoice: {
        'headers': [
            { text: '', align: 'center', value: 'num', sortable: false },
            { text: 'ออเดอร์', align: 'center', value: 'orderID', sortable: false },
            { text: 'เลขแทรค', align: 'center', value: 'trackingNo', sortable: false },
            { text: 'วันที่สร้าง', align: 'center', value: 'createdDatetime', sortable: false },
            { text: 'วันที่แพ็ค', align: 'center', value: 'packedDate', sortable: false },
            { text: 'ขนส่ง', align: 'center', value: 'expressCompanyLogo', sortable: false },
            // { text: 'ขนส่ง', align: 'center', value: 'expressCompanyName', sortable: false },
            { text: 'ค่าส่ง', align: 'right', value: 'deliveryPrice', sortable: false },
            { text: 'ค่าพื้นที่ห่างไกล', align: 'right', value: 'remotePrice', sortable: false },
            { text: 'ค่า COD', align: 'right', value: 'codPrice', sortable: false },
            { text: 'ค่ากล่อง', align: 'right', value: 'boxPrice', sortable: false },
            { text: 'ค่าแพค', align: 'right', value: 'packPrice', sortable: false },
            { text: 'ค่าบับเบิล', align: 'right', value: 'bubblePrice', sortable: false },
            { text: 'ส่วนลด', value: 'discount', sortable: false, align: 'right' },
            { text: 'ค่าเชื่อมต่อ Vrich', value: 'vrichFee', sortable: false, align: 'right' },
            { text: 'ราคารวม', align: 'right', value: 'totalPrice', sortable: false },
          ],
        'key_table': 0,
        'table': [],
        'invoiceId': ''
      },

      ch_InvoiceGroupID: null,
      item_invoice_list: [],
      headers_item: [
          { text: 'รายละเอียด', align: 'left', value: 'description', sortable: false },
          { text: 'จำนวน', align: 'right', value: 'quantity', sortable: false },
          { text: 'ราคาต่อหน่วย', align: 'right', value: 'unitPrice', sortable: false },
          { text: 'รวม', align: 'right', value: 'totalPrice', sortable: false },
        ],
      // paginations
      page: 1,

      // ข้อมูล dialog invoice

      shopDisibled: true,
      branchDisibled: true,
      postcode_mapping: null,

      // ออกใบกำกับภาษี shop
      Shop_FullAddress_text: null,
      ShopAddressInvoice: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

      // ออกใบกำกับภาษี branch
      branch_FullAddress_text: null,
      BranchAddressInvoice: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

      excel_detail_invoice_list_header: [
        { label: "ออเดอร์", field: "orderID", width: 15},
        { label: "เลขแทรค", field: "trackingNo", width: 25 },
        { label: "วันที่สร้าง", field: "createdDatetime", width: 25 },
        { label: "วันที่แพ็ค", field: "packedDate", width: 25 },
        { label: "ขนส่ง", field: "expressCompanyName", width: 25 },
        { label: "น้ำหนัก", field: "boxWeight", width: 15 },
        { label: "กว้าง", field: "boxWeight", width: 15 },
        { label: "ยาว", field: "boxLong", width: 15 },
        { label: "สูง", field: "boxHigh", width: 15 },
        { label: "เลขอ้างอิง", field: "referenceNo", width: 30 },
        { label: "ค่าส่ง", field: "deliveryPrice", width: 15 },
        { label: "ค่าพื้นที่ห่างไกล", field: "remotePrice",  width: 15 },
        { label: "ค่า COD", field: "codPrice", width: 15 },
        { label: "ค่ากล่อง", field: 'boxPrice', width: 15 },
        { label: "ค่าแพค", field: "packPrice", width: 15 },
        { label:  "ค่าบับเบิล", field: "bubblePrice", width: 15 },
        { label: "ส่วนลด", field: "discount", width: 15},
        { label: "ค่าเชื่อมต่อ Vrich", field: "vrichFee", width: 15},
        { label: "ราคารวม", field: "totalPrice", width: 15},
      ],

      data_group_detail: null,
      data_group_detailt_for_excel: [],

      // param check
      detail_invoiceGroupID : null,
      detail_totalItemPrice : null ,
      detail_calculatedPrice : null ,
      detail_vatType : null ,
      detail_vatAmount : null ,
      detail_totalPrice : null ,

      invoiceList_first: null,
      rd_branch_action: '1',
      rd_shop_action: '1',
      select_branch_ad_iv_id: null,
      branch_ad_iv_list: [],
      select_shop_ad_iv_id: null,
      shop_ad_iv_list: [],

      // invoice item
      invoiceItemDisibled: true,
      radio_vat_type_select: "3",

      // created bill
      dialog_created_bill: false,
      bill_description: null,
      bill_quantity: null,
      bill_price:null,
      bill_for_created_list: [],

      // sum
      sum_price: 0,
      sum_price_vat:0,
      sum_vat: 0,
      sum_all: 0,

      dialog_select_print: false,
      select_print_type: 1,
      select_print_type_List: [
        { "label": "ใบแจ้งหนี้", "value": 1 },
        { "label": "ใบเสร็จรับเงิน / ใบกำกับภาษี", "value": 2 },
        { "label": "ใบเสร็จรับเงิน", "value": 3 },
        { "label": "ใบกำกับภาษี", "value": 4 },
      ],
      item_for_print: null,

      selectInvoiceDate: null,
      menuSelectInvoiceDate: false,
      checkInvoiceDate: true,

    }),
    async created() {
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      this.data_query_invoiceNo = this.$route.query.invoiceNo
      document.title = 'ใบแจ้งหนี้เลขที่ '+this.data_query_invoiceNo
      this.postcode_mapping = await this.getPostcodeMapping();
      await this.getInvoice()
      await this.get_address_invoice_shop_branch()
      await this.openListOrderInvoice()
      await this.getInvoiceItems()
      this.page_loading = false
    },
    computed: {
      computed_search_sent_date() {
        if(this.date_range[0] != null) {
          return this.formatDate(this.date_range)
        } else {
          return null
        }
      },
    },
    watch: {
      selectInvoiceDate: async function(newVal, oldVal) {
        if(oldVal != null){
          this.$refs.menuSelectInvoiceDate.save(this.selectInvoiceDate)
        }
      },
    },
    methods: {
       

      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      async getInvoiceItems () {
        try {
          let res_iv_items = await axios.get(reportService_dotnet+'Invoice/get-invoice-detail?InvoiceID='+this.invoiceList_first.id,
          { headers: header_token})
          this.radio_vat_type_select = res_iv_items.data.invoiceDetail.vatType.toString()
          this.bill_for_created_list = res_iv_items.data.invoiceItems
          for (var item of this.bill_for_created_list) {
            item['total_price'] = item.quantity * item.unitPrice
          }
          this.calculateVAT()
        } catch (e) {
          this.AlertError()
        }
      },

      async get_address_invoice_shop_branch () {
        let res_branch = await axios.post(branchService_dotnet+'Branch/get-branch-address-invoice-list', {
          "id": null,
          "branchID": localStorage.getItem('Branch_BranchID'),
          "name": null,
          "phone": null,
          "address": null,
          "isAscending": true,
          "orderBy": 2
        },
        { headers: header_token})

        let res_shop = await axios.post(shopService_dotnet+'ShopAddressInvoice/get-shop-address-invoice-list', {
          "id": null,
          "shopID": this.invoiceList_first.shopID ,
          "name": null,
          "phone": null,
          "address": null,
          "isAscending": true,
          "orderBy": 2
        },
        { headers: header_token})

        this.branch_ad_iv_list = res_branch.data
        this.shop_ad_iv_list = res_shop.data

        this.select_branch_ad_iv_id = this.invoiceList_first.branchAddressInvoiceID
        this.select_shop_ad_iv_id = this.invoiceList_first.shopAddressInvoiceID
      },

      async getInvoice() {
        try {
          let res1 = await axios.post(reportService_dotnet+'Invoice/invoice-list', {
            "branchID": localStorage.getItem('Branch_BranchID'),
            "shopID": null,
            "invoiceNo" : this.data_query_invoiceNo ,
            "dateFrom": null,
            "dateTo": null,
            "skip": 0,
            "take": 1
          },
          { headers: header_token})
            this.invoiceList_first = res1.data.invoiceList[0]
            this.detail_shopName = this.invoiceList_first.shopName
            this.detail_createdByName = this.invoiceList_first.createdByName
            this.detail_created = this.invoiceList_first.createdDatetime
            this.detail_groupName = this.invoiceList_first.groupName
        } catch (e) {
          this.AlertError()
        }
      },

      calculateTotal(item) {
        var result = 0
        if(item.RemotePrice != '' && item.RemotePrice != null){
          result += parseFloat(item.RemotePrice)
        }
        if(item.CODCharged != '' && item.CODCharged != null){
          result += parseFloat(item.CODCharged)
        }
        if(item.PackPrice != '' && item.PackPrice != null){
          result += parseFloat(item.PackPrice)
        }
        if(item.BoxPrice != '' && item.BoxPrice != null){
          result += parseFloat(item.BoxPrice)
        }
        if(item.DeliveryPrice != '' && item.DeliveryPrice != null){
          result += parseFloat(item.DeliveryPrice)
        }
        if(item.Discount != '' && item.Discount != null){
          result += parseFloat(item.Discount)
        }

        if(item.ServiceVatAmount != '' && item.ServiceVatAmount != null){
          result += parseFloat(item.ServiceVatAmount)
        }

        if(item.CODVatAmount != '' && item.CODVatAmount != null){
          result += parseFloat(item.CODVatAmount)
        }
        return result.toFixed(2)
      },

      detail_order (item) {
        window.open('/order-detail?token='+item.token);
      },

      async openListOrderInvoice(){
        var row = this.invoiceList_first
        this.detail_vatType = row.vatType
        this.detail_vatAmount = row.vatAmount
        this.detail_totalPrice = row.totalPrice
        this.detail_totalItemPrice = row.totalItemPrice
        this.detail_calculatedPrice = row.calculatedPrice

        this.detail_invoiceGroupID = null
        this.detail_invoiceGroupID = row.invoiceGroupID

        await this.get_data_aadress(row)

        if(row.invoiceGroupID == null){
          this.loading = true
          let res1 = await axios.post(reportService_dotnet+'Invoice/group-detail', {
            "id": row.id
          },
          { headers: header_token})
          this.listOrderInvoice['table'] = []
          this.listOrderInvoice['table'] = res1.data.itemList
          this.listOrderInvoice['invoiceId'] = row.id
          this.listOrderInvoice['invoiceNo'] = row.invoiceNo
          this.listOrderInvoice['invoiceDate'] = row.invoiceDate

          this.item_invoice_list = res1.data.itemListGroupIsNull
          this.ch_InvoiceGroupID = res1.data.invoiceGroupID
          this.data_group_detail = res1.data
          this.data_group_detailt_for_excel = []

          this.loading = false
        }
        if (row.invoiceGroupID != null || row.groupName != null) {
          this.loading = true
          let res1 = await axios.post(reportService_dotnet+'Invoice/group-detail1', {
            "id": row.invoiceGroupID == null ? 0 : row.invoiceGroupID,
            "groupName": row.groupName
          },
          { headers: header_token})

          this.listOrderInvoice['table'] = []
          this.listOrderInvoice['table'] = res1.data.itemList
          this.listOrderInvoice['invoiceId'] = row.id
          this.listOrderInvoice['invoiceNo'] = row.invoiceNo
          this.listOrderInvoice['invoiceDate'] = row.invoiceDate

          this.item_invoice_list = res1.data.itemListGroupIsNull
          this.ch_InvoiceGroupID = res1.data.invoiceGroupID

          this.data_group_detail = []
          this.data_group_detailt_for_excel = []
          this.data_group_detail = res1.data
          this.data_group_detailt_for_excel = [...res1.data.itemList]

          this.data_group_detailt_for_excel.push({"vrichFee": "ค่าขนส่ง" , "totalPrice": this.data_group_detail.deliveryPrice})
          this.data_group_detailt_for_excel.push({"vrichFee": "ค่าบริการ COD" , "totalPrice": this.data_group_detail.codCharged})
          this.data_group_detailt_for_excel.push({"vrichFee": "ภาษีมูลค่าเพิ่ม" , "totalPrice": this.data_group_detail.codVatAmount})
          this.data_group_detailt_for_excel.push({"vrichFee": "ค่าบริการอื่นๆ" , "totalPrice": this.data_group_detail.servicePrice})
          this.data_group_detailt_for_excel.push({"vrichFee": "ภาษีมูลค่าเพิ่ม" , "totalPrice": this.data_group_detail.serviceVatAmount})
          // this.data_group_detailt_for_excel.push({"vrichFee": "ส่วนลด" , "totalPrice": this.data_group_detail.discount})
          this.data_group_detailt_for_excel.push({"vrichFee": "รวมยอดทั้งหมด" , "totalPrice": this.data_group_detail.totalPrice})

          this.loading = false
        }
      },

      async get_data_aadress (item) {
        var ShopAddressID = null
        var BranchAddressID = null

        if(item['shopAddressInvoiceID'] != null){ShopAddressID = item['shopAddressInvoiceID']}
        if(item['branchAddressInvoiceID'] != null){BranchAddressID = item['branchAddressInvoiceID']}

        this.loading = true

        let res1 = await axios.post(shopService_dotnet+'ShopAddressInvoice/get-shop-address-invoice', {
          'id': ShopAddressID
        },
        { headers: header_token})

        let res2 = await axios.post(branchService_dotnet+'Branch/get-branch-address-invoice', {
          'id': BranchAddressID
        },
        { headers: header_token})

          // address shop >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

          if (res1.data != null){

            this.Shop_FullAddress_text = res1.data['fullAddress']
          } else {
            this.ShopAddressInvoice= {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID": null,
              "IsDefault": true,
            }

            this.Shop_FullAddress_text= null
          }

          // address branch >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          if (res2.data != null){
            this.branch_FullAddress_text = res2.data['fullAddress']
          } else {
            this.BranchAddressInvoice= {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID": null,
              "IsDefault": true,
            }

            this.branch_FullAddress_text= null
          }
          this.loading = false
        },

        async AddandEditShopAddressInvoice (type) {
          if (this.get_premission_manage(22) == false) {
            this.permission_alert()
            return
          }

          if(type == 'update'){
            this.loading = true

            let res1 = await axios.post(reportService_dotnet+'Invoice/invoice-update-addressid-branch-and-shop', {
              "invoiceID": this.invoiceList_first.id,
              "branchInvoiceAddressID": null,
              "shopInvoiceAddressID": this.select_shop_ad_iv_id,
            },{ headers: header_token})
          }else {
            if(this.ShopAddressInvoice.Name == null || this.ShopAddressInvoice.Name == ''){
              this.$refs.refShopAddressInvoiceName.focus()
              return
            } else if (this.ShopAddressInvoice.Phone == null || this.ShopAddressInvoice.Phone == '') {
              this.$refs.refShopAddressInvoicePhone.focus()
              return
            } else if (this.ShopAddressInvoice.TextAddress == null || this.ShopAddressInvoice.TextAddress == '') {
              this.$refs.refShopAddressInvoiceTextAddress.focus()
              return
            } else if (this.ShopAddressInvoice.Address == null || this.ShopAddressInvoice.Address == '') {
              this.$refs.refShopAddressInvoiceAddress.focus()
              return
            }


            this.loading = true
            let res2 = await axios.post(shopService_dotnet+'ShopAddressInvoice/update-shop-address-invoice', {
              "id": 0,
              "shopId": this.invoiceList_first.shopID,
              "name": this.ShopAddressInvoice.Name,
              "idcardNumber": this.ShopAddressInvoice.IDCardNumber,
              "phone": this.ShopAddressInvoice.Phone,
              "address": this.ShopAddressInvoice.TextAddress,
              "postcodeMappingId": this.ShopAddressInvoice.Address.id,
              "isDefault": true,
              "isDeleted": false,
              "shopLogo": null
            },{ headers: header_token})

            var last_insert_id ;
            for (var item of res2.data) {
              if(item.isDefault == true){
                last_insert_id = item.id ;
                break;
              }
            }

            let res3 = await axios.post(reportService_dotnet+'Invoice/invoice-update-addressid-branch-and-shop', {
              "invoiceID": this.invoiceList_first.id,
              "branchInvoiceAddressID": null,
              "shopInvoiceAddressID": last_insert_id
            },{ headers: header_token})

            this.ShopAddressInvoice = {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID": null,
              "IsDefault": true,
            }

            this.loading = false
          }

          this.branchDisibled = true
          this.shopDisibled = true

          this.loading = false
          await this.getInvoice()
          await this.get_address_invoice_shop_branch()
          await this.openListOrderInvoice()
        },

        async AddandEditBranchAddressInvoice (type) {

          if (this.get_premission_manage(22) == false) {
            this.permission_alert()
            return
          }

          if(type == 'update'){
            this.loading = true

            let res1 = await axios.post(reportService_dotnet+'Invoice/invoice-update-addressid-branch-and-shop', {
              "invoiceID": this.invoiceList_first.id,
              "branchInvoiceAddressID": this.select_branch_ad_iv_id,
              "shopInvoiceAddressID": null
            },{ headers: header_token})


          }else {
            if(this.BranchAddressInvoice.Name == null || this.BranchAddressInvoice.Name == ''){
              this.$refs.refBranchAddressInvoiceName.focus()
              return
            } else if (this.BranchAddressInvoice.Phone == null || this.BranchAddressInvoice.Phone == '') {
              this.$refs.refBranchAddressInvoicePhone.focus()
              return
            } else if (this.BranchAddressInvoice.TextAddress == null || this.BranchAddressInvoice.TextAddress == '') {
              this.$refs.refBranchAddressInvoiceTextAddress.focus()
              return
            } else if (this.BranchAddressInvoice.Address == null || this.BranchAddressInvoice.Address == '') {
              this.$refs.refBranchAddressInvoiceAddress.focus()
              return
            }

            this.loading = true
            let res2 = await axios.post(branchService_dotnet+'Branch/update-branch-address-invoice', {
              "id": 0,
              "branchId": localStorage.getItem('Branch_BranchID'),
              "name": this.BranchAddressInvoice.Name,
              "idcardNumber": this.BranchAddressInvoice.IDCardNumber,
              "phone": this.BranchAddressInvoice.Phone,
              "address": this.BranchAddressInvoice.TextAddress,
              "postcodeMappingId": this.BranchAddressInvoice.Address.id,
              "tumbon": this.BranchAddressInvoice.Address.subDistrict,
              "amphoe": this.BranchAddressInvoice.Address.district,
              "province": this.BranchAddressInvoice.Address.province,
              "postcode": this.BranchAddressInvoice.Address.postcode,
              "isDefault": true,
              "isDeleted": false,
              "logo": null
            },{ headers: header_token})

            var last_insert_id ;
            for (var item of res2.data) {
              if(item.isDefault == true){
                last_insert_id = item.id ;
                break;
              }
            }

            let res3 = await axios.post(reportService_dotnet+'Invoice/invoice-update-addressid-branch-and-shop', {
              "invoiceID": this.invoiceList_first.id,
              "branchInvoiceAddressID": last_insert_id,
              "shopInvoiceAddressID": null
            },{ headers: header_token})

            this.loading = false

            this.BranchAddressInvoice= {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID": null,
              "IsDefault": true,
            }
          }
          this.branchDisibled = true
          this.shopDisibled = true

          this.loading = false
          await this.getInvoice()
          await this.get_address_invoice_shop_branch()
          await this.openListOrderInvoice()
        },

        add_data_bill () {
          var data_dict = {"id": (this.bill_for_created_list.length + 1) * -1, "invoiceID": this.invoiceList_first.id, "description": this.bill_description, "quantity": this.bill_quantity ,"unitPrice": this.bill_price, "total_price": 0}
          this.bill_for_created_list.push(data_dict)
          this.bill_description = null
          this.bill_quantity = null
          this.bill_price = null
        },

        delete_item (item) {
          if(item.description == null || item.description == ''){
            this.bill_for_created_list.splice(this.bill_for_created_list.indexOf(item), 1)
          }else {
            Swal.fire({
              position: "top",
              icon: 'warning',
              // title: 'ยกเลิกใบแจ้งหนี้',
              text: 'ต้องการลบ ' + item.description + ' หรือไม่ !!',
              confirmButtonText: 'ยืนยัน',
              cancelButtonText: 'ยกเลิก',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              allowOutsideClick: false

            }).then((result) => {
              if (result.value) {
                this.bill_for_created_list.splice(this.bill_for_created_list.indexOf(item), 1)
              }
            })
          }

        },

        async update_iv_item () {
          if (this.get_premission_manage(22) == false) {
            this.permission_alert()
            return
          }
          
          // description
          for (var i1 = 0; i1 < this.bill_for_created_list.length; i1++) {
            if(this.bill_for_created_list[i1].description == null || this.bill_for_created_list[i1].description == null ){
              this.$refs.descriptionInput.focus()
              return
            }
          }
          // quantity
          for (var i2 = 0; i2 < this.bill_for_created_list.length; i2++) {
            if(this.bill_for_created_list[i2].quantity == null || this.bill_for_created_list[i2].quantity <= 0 ){
              this.$refs.quantityInput.focus()
              return
            }
          }
          // unitPrice
          // for (var i3 = 0; i3 < this.bill_for_created_list.length; i3++) {
          //   if(this.bill_for_created_list[i3].unitPrice == null || this.bill_for_created_list[i3].unitPrice <= 0 ){
          //     this.$refs.priceInput.focus()
          //     return
          //   }
          // }
          for (var item1 of this.bill_for_created_list) {
            delete item1['total_price']
            item1.quantity = parseInt(item1.quantity)
            item1.unitPrice = parseFloat(item1.unitPrice)
          }

          for (var ic = 0; ic < this.bill_for_created_list.length; ic++) {
            this.bill_for_created_list[ic]['order'] = ic + 1 ;
          }

          try {
            this.loading = true
            let res = await axios.post(reportService_dotnet+'Invoice/edit-invoice-item', {
              "invoiceID": this.invoiceList_first.id,
              "staffBranchID": parseInt(localStorage.getItem('Branch_StaffID')),
              "vattype": parseInt(this.radio_vat_type_select) ,
              "invoiceItem": this.bill_for_created_list
            },
            { headers: header_token})
            if (res.status == 200) {
              this.AlertSuccess()
              await this.getInvoice()
              await this.getInvoiceItems()
              this.invoiceItemDisibled = true
            }
            this.loading = false
          } catch (e) {
            this.AlertError()
            this.loading = false
          }

        },

        descriptionInput (item) {
          if(item.description == null || item.description == ''){
            this.$refs.descriptionInput.focus()
            return
          }
        },

        quantityInput (item) {
          if(item.quantity == null || item.quantity == 0){
            this.$refs.quantityInput.focus()
            return
          }

          if(item.quantity != null && item.quantity > 0 && item.unitPrice != null  ){ // && item.unitPrice > 0
            item.total_price = item.quantity * item.unitPrice
          }
          this.calculateVAT()
        },

        priceInput (item) {
          if(item.unitPrice == null){ // || item.unitPrice == 0
            this.$refs.priceInput.focus()
            return
          }

          if(item.quantity != null && item.quantity > 0 && item.unitPrice != null  ){ // && item.unitPrice > 0
            item.total_price = item.quantity * item.unitPrice
          }
          this.calculateVAT()
        },

        calculateVAT () {
          this.sum_price = 0
          this.sum_price_vat = 0
          this.sum_vat = 0
          this.sum_all = 0

          if(this.bill_for_created_list.length > 0){

            for (var i = 0; i < this.bill_for_created_list.length; i++) {
              if(this.bill_for_created_list[i].total_price != null) {
                this.sum_price += this.bill_for_created_list[i].total_price
              }

            }

            if(this.radio_vat_type_select == '1'){
              this.sum_all = this.sum_price
              this.sum_price_vat = this.sum_price
            } else if (this.radio_vat_type_select == '2') {
              this.sum_vat = ( this.sum_price * 7 ) / 107
              this.sum_price_vat = this.sum_price - this.sum_vat

              this.sum_all = this.sum_price
            } else if (this.radio_vat_type_select == '3') {

              this.sum_vat = (this.sum_price * 7)/100
              this.sum_price_vat =  this.sum_price
              this.sum_all = this.sum_vat + this.sum_price
            }

          }
        },
        back_allinvoice () {
          this.$router.push('/all-invoice')
        },

        dialog_print_open () {
          var val = this.listOrderInvoice.invoiceDate
          var date_tmp = val.substring(0, 10)
          this.selectInvoiceDate = date_tmp
          this.dialog_select_print = true
        },

        async PrintPreview(){
          var  item = this.invoiceList_first
          var invoiceGroupID_tmp ;
          var iv_date ;
          if(item.invoiceGroupID == null && this.listOrderInvoice.table == null){
            // สำหรับ bill
            invoiceGroupID_tmp = item.invoiceGroupID
            if (invoiceGroupID_tmp != null){
              invoiceGroupID_tmp = invoiceGroupID_tmp.toString()
            }

            const date_split = this.selectInvoiceDate.split('-');
            iv_date = date_split[2] + '-' + date_split[1] + '-' + date_split[0]

            window.open('/print-invoice-bill?inv=' + item.id.toString() + "&group=" + invoiceGroupID_tmp + '&type='+this.select_print_type.toString() + '&invoiceDate=' + iv_date + '&groupname=' + item.groupName );
            this.select_print_type = 1
            this.dialog_select_print = false
          }else {
            // แบบปกติ
            invoiceGroupID_tmp = item.invoiceGroupID
            if (invoiceGroupID_tmp != null){
              invoiceGroupID_tmp = invoiceGroupID_tmp.toString()
            }

            const date_split = this.selectInvoiceDate.split('-');
            iv_date = date_split[2] + '-' + date_split[1] + '-' + date_split[0]

            window.open('/print-invoice?inv=' + item.id.toString() + "&group=" + invoiceGroupID_tmp + '&type='+this.select_print_type.toString() + '&invoiceDate=' + iv_date + '&groupname=' + item.groupName );
            this.select_print_type = 1
            this.dialog_select_print = false
          }

        },

        FormatInvoice,
        FormatOrder,
        formatMoney,
        isNumberWNoDot,
        set_format_date_time,
        format_price,
        format_vat_type,
        AlertSuccess,
        AlertWarning,
        AlertError,
        getPostcodeMapping,
        get_premission_manage,
        permission_alert
    }
  }
</script>

<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
      display: none;
  }

  .mobile-none {
    display: ;
  }

  .pc-none {
    display: none;
  }

  @media (max-width: 599px) {
    .mobile-none {
      display: none;
    }

    .pc-none {
      display: ;
    }
  }
</style>
